import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';
import {
  configs,
  eProduct,
  eLocale,
  eBusinessType,
  eField,
  ePolicyNumberLength,
  eLang,
  eVitalityType,
} from '../core/configs';
import {
  getKeyByValue,
  isFeatureFlagSet,
  trackInforceErrors,
  prepareInforceRequestFormPayload,
  isAlphaNumericSpaceChar,
} from '../core/utils';
import { policyLookup, createInforceRequestForm, policyEntitled } from '../core/udm-mapper/sideEffect';
import MWIInput from '../core/components/mwi-input';
import Banner from '../components/banner';
import MWIButton from '../core/components/mwi-button';
import MWIAccordion from '../core/components/mwi-accordion';
import ManulifeParDescription from './par-description';
import FamilyTermDescription from './family-term-description';
import LifechequeDescription from './lifecheque-description';
import FamilyTermTrcDescription from './family-term-trc-description';
import ManulifeULDescription from './mul-description';
import ManulifeULInforceDescription from './inforce/mul-inforce-description';
import PerformaxGoldInforceDescription from './inforce/pgold-inforce-description';
import ParInforceDescription from './inforce/par-inforce-description';
import TermInforceDescription from './inforce/term-inforce-description';
import * as Actions from '../core/actions';
import * as LandingPageActions from './actions';
import './landing-page.css';
import MWISROnly from '../core/components/mwi-sr-only';
import DocumentTitle from 'react-document-title';
import { Manulife } from '@manulife/mux-logos';
import { brandingColors } from '../assets/colors';
import { Icon, RadioGroup } from '@manulife/mux';
import { Growl } from 'primereact/growl';
import ReactModal from 'react-modal';

const commonLoading = 'common.loading';
const fontOpenSansBold = 'OpenSans-Bold';

export class LandingPage extends PureComponent {
  static propTypes = {
    app: PropTypes.object,
    advisorProfile: PropTypes.object,
    appActions: PropTypes.object.isRequired,
    product: PropTypes.string,
    entitledProduct: PropTypes.string,
    showIllustration: PropTypes.bool,
    policyNumber: PropTypes.string,
    businessType: PropTypes.string,
    history: PropTypes.object,
    intl: PropTypes.object,
    locale: PropTypes.string,
    errorStatus: PropTypes.string,
    errors: PropTypes.string,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isInforceFormLoading: PropTypes.bool,
    landingPageActions: PropTypes.object,
    errorMessage: PropTypes.string,
    isVitalityPlusSelected: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      multiPolicies: [],
      productOptions: [],
      selectedProduct: '',
      showProductSelect: false,
      isWarning: false,
      warningMessage: '',
    };
    this.goToProduct = this.goToProduct.bind(this);
    this.goToInforceProduct = this.goToInforceProduct.bind(this);
    this.checkInforceProduct = this.checkInforceProduct.bind(this);
    this.goToIRForm = this.goToIRForm.bind(this);
    this.goToTermRenewalCalc = this.goToTermRenewalCalc.bind(this);
    this.loadPolicyByProduct = this.loadPolicyByProduct.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.onShowProductSelector = this.onShowProductSelector.bind(this);
    this.onHideProductSelector = this.onHideProductSelector.bind(this);
    this.onWholesalerClick = this.onWholesalerClick.bind(this);
    this.searchInforcePolicy = this.searchInforcePolicy.bind(this);
    this.setSelectedProduct = this.setSelectedProduct.bind(this);
    this.launchButton = React.createRef();
    this.startIllustrationButton = React.createRef();
    this.searchButton = React.createRef();
    this.focusLaunchButton = this.focusLaunchButton.bind(this);
  }

  // todo potentially load profiles here
  componentDidMount() {
    const { product } = this.props;
    if (product) {
      this.setState({ product });
    }
  }

  componentDidUpdate(prevProps) {
    const { isError, errorStatus } = this.props;
    // Will run only once, after the error occurs.
    if (isError && prevProps.isLoading) {
      trackInforceErrors('InforceError', errorStatus);
    }
  }

  componentWillUnmount() {
    this.dispatchAction(eField.policyNumber, '');
  }

  dispatchAction = function (field, value) {
    if (this.props.appActions['dispatchMWIOnChange']) {
      this.props.appActions['dispatchMWIOnChange']({
        target: 'landingPage',
        field,
        value,
      });
    }
  };

  onBusinessTypeChange = function (name, businessType) {
    this.setState({ businessType, errors: '', isError: false });
    this.props.landingPageActions.selectBusinessType(businessType);
    if (businessType !== eBusinessType.IF) {
      this.dispatchAction(eField.policyNumber, '');
    }
  };

  onProductChange(product) {
    if ([eProduct.IF, eProduct.TermInforce].includes(product)) {
      this.setState({ product, entitledProduct: '', showIllustration: false, businessType: eBusinessType.IF });
      this.dispatchAction(eField.businessType, eBusinessType.IF);
      this.props.landingPageActions.resetInforceScreen();
    } else {
      this.setState({ product, entitledProduct: '', showIllustration: false, businessType: eBusinessType.NB });

      if (this.state.businessType !== eBusinessType.NB) {
        this.dispatchAction(eField.businessType, eBusinessType.NB);
        this.dispatchAction(eField.policyNumber, '');
      }
    }
  }

  onWholesalerClick() {
    const url = this.props.intl.formatMessage({ id: 'landing.contactwholesaler' });
    window.open(url);
  }

  onShowProductSelector() {
    this.setState({ showProductSelect: true });
  }

  onHideProductSelector(cancelled = false) {
    this.setState({ showProductSelect: false, selectedProduct: '' });

    if (cancelled) {
      this.props.landingPageActions.resetInforceScreen();
    }
  }

  focusLaunchButton() {
    if (this.launchButton.current) {
      this.launchButton.current.mwiButton.current.element.focus();
    }
  }

  getInforceProductTitleKey = () => {
    const isVitality = !isFeatureFlagSet() && this.state.isVitalityPlusSelected;
    if (isVitality) {
      return `product.${this.state.entitledProduct.toLocaleLowerCase()}.vp.inforce.title`;
    }
    return `product.${this.state.entitledProduct.toLocaleLowerCase()}.inforce.title`;
  };

  isIrfError() {
    return (
      [eProduct.IF, eProduct.TermInforce].includes(this.props.product) &&
      ![eProduct.FT, eProduct.BT, eProduct.MUL].includes(this.state.entitledProduct) &&
      (this.props.errors === 'landing.inforce.error.redflag' ||
        this.props.errors === 'landing.inforce.error.waiver' ||
        this.props.errors === 'landing.inforce.error.UseSpecialRequestForm' ||
        this.props.errors === 'landing.inforce.error.specialquote.PAR' ||
        this.props.errors === 'landing.inforce.error.CPRNotSupported' ||
        this.props.errors === 'landing.inforce.error.TermOptionPlusCoverageWithoutTermOptionCoverageNotSupported' ||
        this.props.errors === 'landing.inforce.error.NoPaymentsError')
    );
  }

  isPolicySelfServe() {
    const entitledProduct = this.state.entitledProduct ?? '';
    const isProductEligble = [
      eProduct.FT.toLocaleLowerCase(),
      eProduct.BT.toLocaleLowerCase(),
      eProduct.Performax.toLocaleLowerCase(),
      eProduct.SB.toLocaleLowerCase(),
      eProduct.MUL.toLocaleLowerCase(),
      eProduct.PG.toLocaleLowerCase(),
      eProduct.PAR.toLocaleLowerCase(),
    ].includes(entitledProduct.toLocaleLowerCase());

    return [eProduct.IF, eProduct.TermInforce].includes(this.props.product) && isProductEligble;
  }

  isValidPolicyNumber(policyNumber, isLoading) {
    const minCheckLength = policyNumber.length > 0;
    const maxCheckLength = policyNumber.length <= ePolicyNumberLength[this.state.product];
    return !(
      this.props.policyNumber &&
      !isLoading &&
      minCheckLength &&
      maxCheckLength &&
      isAlphaNumericSpaceChar(policyNumber)
    );
  }

  processInforceRequestForm = (response) => {
    this.setState({ isInforceFormLoading: false });
    if (response?.indexOf('Error') !== -1 || !response?.toLocaleLowerCase().startsWith('http', 0)) {
      throw response;
    }

    this.props.appActions.loadedInforceRequestForm(response);
    window.open(response);
  };

  processInforceResponse = (response, navigate = true) => {
    if (!response.status || response.status !== 'Success') {
      this.setState({ showIllustration: false });
      this.dispatchAction('showIllustration', false);
      throw response;
    }
    if (response.policyData.status !== 'Inforce') {
      this.setState({ showIllustration: false });
      this.dispatchAction('showIllustration', false);
      throw response;
    }
    this.props.landingPageActions.loadedInforcePolicy(response);
    if (!isFeatureFlagSet() && response.policyData.illustration.policy.product === eProduct.PAR) {
      this.setState({
        isVitalityPlusSelected: response.policyData.illustration.policy.vitality.type === eVitalityType.plus,
      });
    }

    this.checkInforceProductWarning(response);

    this.setState({ showIllustration: true });
    this.dispatchAction('showIllustration', true);
    if (navigate) {
      this.goToIllustrations();
    }
  };

  processEntitledResponse = (response) => {
    const product = response.entitledPolicy[0].product;
    if ([eProduct.PAR].includes(product) && isFeatureFlagSet()) {
      // disable Par Inforce in Prod until it is ready for release
      const errResp = { status: 'Error', message: 'Could not obtain PolicyId.' };
      throw errResp;
    }

    if (!response.status || response.status !== 'Success') {
      throw response;
    }
    if (response.entitledPolicy[0].status !== 'Entitled') {
      throw response;
    }

    // if entitledPolicy contains more than 1 policy -> launch multi product selection flow
    let hasMultipleProducts = response.entitledPolicy.length > 1;
    if (hasMultipleProducts) {
      let multiProductOptions = [];
      for (let i = 0; i < response.entitledPolicy.length; i++) {
        let productType = response.entitledPolicy[i].product;
        multiProductOptions.push({
          label: this.props.intl.formatMessage({ id: `product.${productType.toLocaleLowerCase()}.inforce.title` }),
          value: productType,
        });
      }

      this.setState({ multiPolicies: response.entitledPolicy });
      this.setState({ productOptions: multiProductOptions });
      this.onShowProductSelector();
    } else {
      this.searchInforcePolicy(response.entitledPolicy, product);
    }
  };

  loadPolicyByProduct() {
    let entitledPolicy = [];
    let selectedProduct = this.state.selectedProduct;
    let policyToLoad = this.state.multiPolicies.find(({ product }) => product === selectedProduct);

    if (policyToLoad) {
      entitledPolicy.push(policyToLoad);
      this.searchInforcePolicy(entitledPolicy, selectedProduct);
      this.onHideProductSelector();
    } else {
      this.onHideProductSelector(true);
    }
  }

  searchInforcePolicy(entitledPolicy, product) {
    this.props.landingPageActions.searchedInforcePolicy({
      ...this.state,
      entitledPolicy: entitledPolicy,
    });

    this.setState({ entitledProduct: product });
    this.dispatchAction('entitledProduct', product);
    this.goToInforceProduct(false);
  }

  setSelectedProduct(value) {
    this.setState({ selectedProduct: value });
  }

  checkInforceProduct() {
    this.props.appActions.appInitialize({ ...this.state, locale: this.props.locale });
    this.props.landingPageActions.searchInforcePolicy({
      ...this.state,
      entitledProduct: '',
      policyNumber: this.props.policyNumber,
    });
    this.setState({ entitledProduct: '' });
    this.dispatchAction('entitledProduct', '');
    this.setState({ showIllustration: false }); // hide start illustration button in case the second policy user launches is not illustrtable.
    this.dispatchAction('showIllustration', false);
    if (!isFeatureFlagSet()) {
      this.setState({ isVitalityPlusSelected: false });
    }
    if (this.state.isWarning) {
      this.setState({ isWarning: false, warningMessage: '' });
    }
    const inforceUrl = configs.endPoints.policyLookup.entitledPolicy;
    const requestData = {
      policyNumber: this.props.policyNumber,
    };
    policyEntitled(requestData, inforceUrl)
      .then(this.processEntitledResponse)
      .catch((error) => {
        if (error.toString() === error) {
          error = { message: error, status: 'Error', entitledProduct: '' };
        }
        this.props.landingPageActions.errorInforcePolicy({ ...error, entitledProduct: '' });
      });
  }

  checkInforceProductWarning(response) {
    if (response.policyData.statusReason === 'RedFlag') {
      this.setState({ isWarning: true, warningMessage: 'landing.inforce.warning.redflag' });
    }
  }

  goToIllustrations() {
    if (this.state.product === eProduct.FTRC) {
      this.props.history.push(configs.routes.termRenewalCalc);
    } else {
      this.props.history.push(configs.routes.clientCoverage);
    }
  }

  goToInforceProduct(navigate = true) {
    let product = this.state.entitledProduct;
    if (!product) {
      // when multiple policies returned use selectedProduct
      product = this.state.selectedProduct;
    }

    this.props.landingPageActions.getInforcePolicy({ ...this.state, policyNumber: this.props.policyNumber });
    const inforceUrl = configs.endPoints.policyLookup.retrievePolicy;
    const requestData = {
      policyNumber: this.props.policyNumber,
      product: product,
      language: eLang[this.props.locale],
    };
    this.props.landingPageActions.loadingInforcePolicy({ ...this.state, policyNumber: this.props.policyNumber });

    const canNavigate = navigate;
    policyLookup(requestData, inforceUrl)
      .then((response) => {
        this.processInforceResponse(response, canNavigate);
      })
      .catch((error) => {
        if (error.toString() === error) {
          error = { message: error, status: 'Error', product: this.state.product };
        }
        this.props.landingPageActions.errorInforcePolicy({ ...error, product: this.state.product });
      });
  }

  goToIRForm() {
    const inforceRequestFormURL = configs.endPoints.inforceRequestForm.create;
    const irfData = { ...this.props.app };
    irfData.openedCaseName = this.props.policyNumber;
    if (this.props.errors.toLowerCase().indexOf('redflag') !== -1) {
      irfData.redFlag = true;
    }
    const payload = prepareInforceRequestFormPayload(irfData);
    // Make Call - Set IsLoading
    this.setState({ isInforceFormLoading: true });
    this.props.appActions.loadingInforceRequestForm({ ...this.state, policyNumber: this.props.policyNumber });
    // Get Response
    createInforceRequestForm(payload, inforceRequestFormURL)
      .then(this.processInforceRequestForm)
      .catch((error) => {
        this.props.appActions.errorInforceRequestForm({ ...this.state, error });
      });
  }

  goToProduct() {
    // TODO: for future product selections, scenarioTabNavs should have a case for SELECT_PRODUCT action
    // that sets the topBar default scenario to the selected product
    this.props.appActions.appInitialize(this.props.locale);
    this.props.landingPageActions.selectProduct(this.state.product);
    // TODO it would be better to move into Saga to deal with error case
    this.goToIllustrations();
  }

  goToTermRenewalCalc() {
    this.props.appActions.appInitialize(this.props.locale);
    this.props.landingPageActions.selectProduct(eProduct.FTRC);
    this.props.history.push(configs.routes.termRenewalCalc);
  }

  renderProductLink(eProd, linkId, messageId) {
    return (
      <a
        href=""
        className={`${this.state.product === eProd ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          this.onProductChange(eProd);
          this.focusLaunchButton();
        }}
        id={linkId}
      >
        <FormattedMessage id={messageId} />
      </a>
    );
  }

  renderDescriptionLink(linkId, messageId, linkAddress, hoverAddress) {
    return (
      <div className="welcome-note">
        <a href={linkAddress} target="blank" title={hoverAddress} className="descritption-link" id={linkId}>
          <FormattedHTMLMessage id={messageId} /> &nbsp;
        </a>
      </div>
    );
  }

  renderLinkSy(eProd, linkId, messageId, linkAddress, hoverAddress) {
    return (
      <div className="welcome-note">
        <a
          href={linkAddress}
          target="blank"
          title={hoverAddress}
          className={`${this.state.product === eProd ? 'active' : ''}`}
          id={linkId}
        >
          <FormattedMessage id={messageId} /> &nbsp;
          <Icon
            alt={hoverAddress}
            manulifeName="external"
            height="12px"
            width="14px"
            fill={brandingColors.m_green}
            color={brandingColors.m_green}
          />
        </a>
      </div>
    );
  }

  renderLink(link, messageId) {
    return (
      <div className="landing-child-wrapper">
        <MWIAccordion
          title={<FormattedMessage id={messageId} />}
          aria-label={
            <h2>
              <FormattedMessage id={messageId} />
            </h2>
          }
          className="mwi-lightGreen accordion-border"
          folded={false}
        >
          <div className="product-selector-content p-grid">{link}</div>
        </MWIAccordion>
      </div>
    );
  }

  renderInforceDescription() {
    if (this.state.entitledProduct === eProduct.MUL) {
      return <ManulifeULInforceDescription intl={this.props.intl} />;
    } else if ([eProduct.Performax, eProduct.SB, eProduct.PG].includes(this.state.entitledProduct)) {
      return (
        <PerformaxGoldInforceDescription
          intl={this.props.intl}
          goToIRForm={this.goToIRForm}
          isLoading={this.state.isInforceFormLoading}
          product={this.state.entitledProduct}
        />
      );
    } else if (this.state.entitledProduct === eProduct.PAR) {
      return (
        <ParInforceDescription
          intl={this.props.intl}
          goToIRForm={this.goToIRForm}
          isLoading={this.state.isInforceFormLoading}
          product={this.state.entitledProduct}
          isVitalityPlus={!isFeatureFlagSet() && this.state.isVitalityPlusSelected}
        />
      );
    } else if ([eProduct.FT, eProduct.BT].includes(this.state.entitledProduct)) {
      return <TermInforceDescription />;
    } else {
      return <></>;
    }
  }

  renderDescription() {
    // change to conditional rendering depending on the selected product
    switch (this.state.product) {
      case eProduct.PAR:
        return <ManulifeParDescription />;
      case eProduct.MUL:
        return <ManulifeULDescription intl={this.props.intl} />;
      case eProduct.IF:
      case eProduct.TermInforce:
        return this.renderInforceDescription();
      case eProduct.FT:
        return <FamilyTermDescription intl={this.props.intl} />;
      case eProduct.LC:
        return <LifechequeDescription />;
      case eProduct.FTRC:
        return <FamilyTermTrcDescription />;
      default:
        return <React.Fragment />;
    }
  }

  renderTermPricesMessage() {
    return (
      <div>
        <div className="product-description  mwi-margin-top20">
          <span style={{ fontFamily: fontOpenSansBold }}>
            <FormattedHTMLMessage id={'landing.important16'} />
          </span>
        </div>
        <div className="mwi-margin-top20">
          <FormattedHTMLMessage id={'landing.announcement.title.termPrices'} />
        </div>
      </div>
    );
  }
  renderDivRecMessage() {
    return (
      <div>
        <div className="product-description  mwi-margin-top20">
          <span style={{ fontFamily: fontOpenSansBold }}>
            <FormattedHTMLMessage id={'landing.important17'} />
          </span>
        </div>
        <div className="mwi-margin-top20">
          <FormattedHTMLMessage id={'landing.announcement.title1.divRec2024'} />
          <a
            href={this.props.intl.formatMessage({ id: 'landing.announcement.divReclink' })}
            target={'_blank'}
            rel="noopener noreferrer"
            className="url-link"
          >
            <FormattedHTMLMessage id="common.learnMore" />
          </a>
        </div>
        <div className="mwi-margin-top20">
          <FormattedHTMLMessage id={'landing.announcement.title2.divRec2024'} />
        </div>
      </div>
    );
  }
  renderHome() {
    return (
      <React.Fragment>
        <div className="welcome-note">
          <h2>
            <FormattedMessage id="landing.welcome" />
          </h2>
        </div>
        <div className="product-description">
          <span style={{ fontFamily: fontOpenSansBold }}>
            <FormattedMessage id="landing.important2" />
          </span>
        </div>
        {this.renderDivRecMessage()}
        {this.renderTermPricesMessage()}
        <div className="mwi-margin-top20">
          <span style={{ fontFamily: fontOpenSansBold }}>
            <FormattedHTMLMessage id={'landing.announcement.title3'} />
          </span>
        </div>
        <div className="mwi-margin-top10">
          <FormattedMessage id="landing.important15" />
        </div>
        <div className="mwi-margin-top10">
          <FormattedHTMLMessage id={'landing.announcement.message4'} />
        </div>
        <div>
          <FormattedHTMLMessage id={'landing.announcement.message5'} />
        </div>
        <div>
          <FormattedHTMLMessage id={'landing.announcement.message6'} />
        </div>
        <div>
          <span style={{ fontFamily: fontOpenSansBold }}>
            <FormattedHTMLMessage id={'landing.announcement.new'} />
          </span>{' '}
          <FormattedHTMLMessage id={'landing.announcement.message7'} />
        </div>
        <div className="mwi-margin-top10">
          <span style={{ fontFamily: fontOpenSansBold }}>
            <FormattedHTMLMessage id={'landing.announcement.new'} />
          </span>{' '}
          <FormattedHTMLMessage id={'landing.announcement.rateChange'} />
          <a
            href={this.props.intl.formatMessage({ id: 'landing.announcement.rateChangelink' })}
            target={'_blank'}
            rel="noopener noreferrer"
            className="url-link"
          >
            <FormattedHTMLMessage id="common.learnMore" />
          </a>
        </div>
      </React.Fragment>
    );
  }

  renderProductHeader() {
    return (
      <div className="product-header" role="heading">
        {/* change header mapping to correlate with selected product for other products
                        maybe us a get KeyByValue method (https://stackoverflow.com/a/28191966) || done this below */}
        {this.props.businessType !== eBusinessType.IF && (
          <h2>
            <FormattedHTMLMessage
              id={`product.${getKeyByValue(eProduct, this.state.product).toLowerCase()}${
                this.state.product === eProduct.MUL ||
                this.state.product === eProduct.IV ||
                this.state.product === eProduct.FT ||
                this.state.product === eProduct.LC ||
                this.state.product === eProduct.PAR
                  ? '.title'
                  : ''
              }`}
            />
          </h2>
        )}
        {this.props.businessType === eBusinessType.IF && (
          <h2>
            <FormattedHTMLMessage id={`product.${getKeyByValue(eProduct, this.state.product).toLowerCase()}`} />
          </h2>
        )}
      </div>
    );
  }

  renderBusinessTypeSelection(intl, policyNumber, businessType, isLoading) {
    if (this.state.product === eProduct.MUL) {
      return (
        <div className="p-grid p-grid-no-margin-side">
          {this.props.businessType === eBusinessType.IF && (
            <div className="p-lg-12">
              <div className="wide-60 margin-bottom10">
                <MWIInput
                  id="policy-number-input"
                  name={eField.policyNumber}
                  label={intl.formatMessage({ id: 'landing.inforce.policynumberlabel' })}
                  placeholder="1234567"
                  sizeStyle="mwi-w100"
                  styleClass="mwi-w100"
                  maxLength={ePolicyNumberLength[this.state.product]}
                  value={policyNumber}
                  onChange={(name, value) => this.dispatchAction(name, value)}
                  grid="15"
                />
              </div>

              {this.props.isError && (
                <div className="message error">
                  <span className="material-icons msg-icon" aria-hidden="true">
                    cancel
                  </span>
                  <span> </span>
                  <MWISROnly label={'Error'} />
                  <FormattedHTMLMessage className="message error" id={this.props.errors} />
                </div>
              )}
            </div>
          )}
        </div>
      );
    } else if ([eProduct.IF, eProduct.TermInforce].includes(this.state.product)) {
      return (
        <div className="p-grid p-grid-no-margin-side">
          <div className="p-sm-8 p-md-8 p-lg-8">
            <MWIInput
              id="policy-number-input"
              name={eField.policyNumber}
              label={intl.formatMessage({ id: 'landing.inforce.policynumberlabel' })}
              placeholder="1234567"
              sizeStyle="mwi-w100"
              styleClass="mwi-w100"
              maxLength={ePolicyNumberLength[this.state.product]}
              value={policyNumber}
              onChange={(name, value) => this.dispatchAction(name, value)}
              grid="15"
            />
          </div>

          {/* Multi-product selector dialog */}
          <ReactModal
            id="product-select-wrapper-id"
            className={`product-select-wrapper`}
            contentLabel={intl.formatMessage({ id: 'landing.product.selector.title' })}
            isOpen={this.state.showProductSelect}
            shouldCloseOnOverlayClick={false}
            shouldFocusAfterRender
            shouldReturnFocusAfterClose
            role="dialog"
            aria={{ modal: 'true' }}
            ariaHideApp
          >
            <div className="p-grid">
              <Growl ref={(el) => (this.growl = el)} />
              {
                <div className="product-selector-content-div">
                  <div className="row-model p-col-12">
                    <h2 className="title-model">
                      <FormattedHTMLMessage id={'landing.product.selector.title'} />
                    </h2>
                    <div className="product-select-radio-div">
                      <RadioGroup
                        direction={RadioGroup.DIRECTION.COLUMN}
                        values={this.state.productOptions}
                        onChange={this.setSelectedProduct}
                      />
                    </div>
                  </div>
                  <div className="productSelectContent row-model p-col-12">
                    <div>
                      <MWIButton
                        name="close"
                        label={intl.formatMessage({ id: 'common.close' })}
                        onClick={(e) => {
                          e.preventDefault();
                          this.onHideProductSelector(true);
                        }}
                        styleClass="cancelProductSelectBtn reset-cancel-button cancel-btn"
                      />
                    </div>
                    <div>
                      <MWIButton
                        name="continue"
                        label={intl.formatMessage({ id: 'session.continue' })}
                        onClick={this.loadPolicyByProduct}
                        id="continueButton"
                        title="continue-btn"
                        disabled={!this.state.selectedProduct}
                        styleClass="continueProductSelectBtn"
                      />
                    </div>
                  </div>
                </div>
              }
            </div>
          </ReactModal>

          <div className="p-sm-3 p-md-3 p-lg-3 align-self-end">
            <MWIButton
              label={
                isLoading ? intl.formatMessage({ id: commonLoading }) : intl.formatMessage({ id: 'landing.if.search' })
              }
              onClick={this.checkInforceProduct}
              disabled={this.isValidPolicyNumber(policyNumber, isLoading)}
              styleClass="start-btn with-padding mwi-w100"
              ref={this.searchButton}
              id="search-button"
            />
          </div>
          <div className="p-sm-8 p-md-8 p-lg-8 margin-top16">
            {this.props.isError && (
              <div className="message error margin-bottom10">
                <span className="material-icons msg-icon" aria-hidden="true">
                  cancel
                </span>
                <span> </span>
                {this.renderErrorMessage(intl)}
              </div>
            )}
            {this.state.isWarning && (
              <div className="message warning margin-bottom10">
                <span className="material-icons warning-icon" aria-hidden="true">
                  warning
                </span>
                <React.Fragment>
                  <MWISROnly label={intl.formatMessage({ id: 'common.warning' })} />
                  <span />
                  <FormattedMessage id={this.state.warningMessage} />
                </React.Fragment>
              </div>
            )}
            <FormattedMessage id="landing.if.infomessage" />
            <a
              href={this.props.intl.formatMessage({ id: 'landing.if.link1' })}
              target={'_blank'}
              rel="noopener noreferrer"
              className="url-link"
            >
              <FormattedHTMLMessage id="landing.all.clickhere" />
            </a>
            <FormattedMessage id="landing.inforce.irfoutro" />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  renderIrfLink(intl) {
    return (
      <FormattedMessage
        id={
          this.state.isInforceFormLoading
            ? intl.formatMessage({ id: commonLoading })
            : intl.formatMessage({ id: 'landing.inforce.irflink' })
        }
      />
    );
  }

  renderIrfLinkErrorMessage(intl) {
    return (
      <React.Fragment>
        <FormattedHTMLMessage id={this.props.errors} />
        <FormattedMessage id="landing.inforce.irfintro" />
        <a
          className="url-link"
          rel="noopener noreferrer"
          target="_blank"
          disabled={this.state.isInforceFormLoading}
          onClick={(e) => {
            e.preventDefault();
            this.goToIRForm();
          }}
        >
          {this.renderIrfLink(intl)}
        </a>
        <FormattedMessage id="landing.inforce.irfoutro" />
      </React.Fragment>
    );
  }

  renderIrfOnlyProductErrorMessage(intl, irfProduct = false) {
    return (
      <React.Fragment>
        <FormattedMessage id="landing.inforce.irfOnlyProduct1" />
        <FormattedHTMLMessage id={this.getInforceProductTitleKey()} />
        <FormattedMessage id="landing.inforce.irfOnlyProduct2" />
        {irfProduct && (
          <>
            <FormattedMessage id="landing.inforce.irfintro" />
            <a
              className="url-link"
              rel="noopener noreferrer"
              target="_blank"
              disabled={this.state.isInforceFormLoading}
              onClick={(e) => {
                e.preventDefault();
                this.goToIRForm();
              }}
            >
              {this.renderIrfLink(intl)}
            </a>
            <FormattedMessage id="landing.inforce.irfoutro" />
          </>
        )}
      </React.Fragment>
    );
  }

  renderUnauthorizedErrorMessage(intl) {
    return this.props.errors === 'landing.inforce.error.unauthorized' ? (
      <React.Fragment>
        <a
          href={intl.formatMessage({ id: 'landing.if.unauthorizedlink1' })}
          target={'_blank'}
          rel="noopener noreferrer"
          className="url-link"
        >
          <FormattedHTMLMessage id="landing.all.clickhere1" />
        </a>
        <FormattedMessage id="landing.inforce.unauthorized" />
      </React.Fragment>
    ) : (
      <></>
    );
  }

  renderErrorMessage(intl) {
    return (
      <React.Fragment>
        <MWISROnly label={'Error'} />
        {this.isPolicySelfServe() && !this.isIrfError() && <FormattedHTMLMessage id={this.props.errors} />}
        {this.isPolicySelfServe() && this.isIrfError() && this.renderIrfLinkErrorMessage(intl)}
        <span> </span>
        {!this.isPolicySelfServe() && !this.isIrfError() && !this.state.entitledProduct && (
          <FormattedHTMLMessage id={this.props.errors} />
        )}
        {!this.isPolicySelfServe() &&
          !this.isIrfError() &&
          this.state.entitledProduct &&
          this.renderIrfOnlyProductErrorMessage(intl)}
        {!this.isPolicySelfServe() && this.isIrfError() && this.renderIrfOnlyProductErrorMessage(intl, true)}
        {this.renderUnauthorizedErrorMessage(intl)}
      </React.Fragment>
    );
  }

  renderStartIllustration(intl, isLoading) {
    return (
      <React.Fragment>
        {this.state.entitledProduct && this.props.businessType === eBusinessType.IF && this.isPolicySelfServe() && (
          <div className="p-grid p-grid-no-margin-side">
            <div className="p-sm-8 p-md-8 p-lg-8 margin-top16">
              <h3>{!isLoading && <FormattedHTMLMessage id={this.getInforceProductTitleKey()} />}</h3>
            </div>
            <div className="p-sm-3 p-md-3 p-lg-3">
              {this.state.showIllustration && !isLoading && (
                <MWIButton
                  label={
                    isLoading
                      ? intl.formatMessage({ id: commonLoading })
                      : intl.formatMessage({ id: 'common.startillustrations' })
                  }
                  onClick={() => {
                    this.setState({ showIllustration: true });
                    this.dispatchAction('showIllustration', true);
                    this.goToIllustrations();
                  }}
                  styleClass="start-btn with-padding mwi-w100"
                  ref={this.startIllustrationButton}
                  id="start-illustration-button"
                />
              )}
            </div>
            <div className="p-grid p-grid-no-margin-side margin-top10" id="product-description">
              {!isLoading && this.renderDescription()}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderProduct(intl, policyNumber, businessType, isLoading) {
    return (
      <React.Fragment>
        {this.renderProductHeader()}
        {this.state.product === eProduct.FTRC && (
          <div className="ftrc-warning">
            <span className="material-icons ftrc-warning-icon" aria-hidden="true">
              error
            </span>
            <MWISROnly label={intl.formatMessage({ id: 'common.warning' })} />
            <FormattedMessage id={'landing.ftrc.warning'} />
          </div>
        )}
        {this.renderBusinessTypeSelection(intl, policyNumber, businessType, isLoading)}
        {this.props.businessType === eBusinessType.NB && (
          <div className="p-grid p-grid-no-margin-side">
            <div className="p-col-2 p-md-3 p-lg-2 margin-top16">
              <MWIButton
                label={
                  isLoading ? intl.formatMessage({ id: commonLoading }) : intl.formatMessage({ id: 'common.launch' })
                }
                onClick={this.props.businessType === eBusinessType.IF ? this.goToInforceProduct : this.goToProduct}
                disabled={
                  this.props.businessType === eBusinessType.IF && this.isValidPolicyNumber(policyNumber, isLoading)
                }
                styleClass="start-btn with-padding mwi-w100"
                ref={this.launchButton}
                data-testid="launch-button-test"
                id="launch-button"
              />
            </div>
            <div className="p-grid p-grid-no-margin-side margin-top16" id="product-description">
              {this.renderDescription()}
            </div>
          </div>
        )}
        {this.renderStartIllustration(intl, isLoading)}
      </React.Fragment>
    );
  }

  renderLifecheque() {
    return this.renderLink(
      <div className="p-col-12">{this.renderProductLink(eProduct.LC, 'lc-link', 'product.lc')}</div>,
      'landing.lifecheque'
    );
  }

  render() {
    const intl = this.props.intl;
    const { locale, policyNumber, businessType, isLoading } = this.props;
    return (
      <DocumentTitle title={intl.formatMessage({ id: 'common.manulife.illustration' })}>
        <React.Fragment>
          <div id="logo-header" className="logoHeader-Height">
            <Manulife.WORDMARK_BLACK // eslint-disable-line
              alt="Manulife Logo"
              role="Header-logo"
              variant={Manulife.WORDMARK_BLACK}
              style={{ height: '4rem', padding: '1rem' }}
              lang={locale === eLocale.en ? 'en-CA' : 'fr-CA'}
            />
          </div>
          <div />
          <Banner />
          {/*<LogoutHandler props={this.props}/>*/}
          <div
            className={`mwi-main p-grid p-grid-no-margin ${locale}`}
            id="landing-main-content"
            aria-controls="product-details"
          >
            {
              <div className="left-section p-col-3">
                <div className="product-selector-content p-grid" id="landing-home">
                  <div className="p-col-12">
                    <a
                      href=""
                      className={`${!this.state.product ? 'active' : ''}`}
                      onClick={(e) => {
                        e.preventDefault();
                        this.onProductChange('');
                      }}
                      id="home-link"
                    >
                      <FormattedMessage id="common.home" />
                    </a>
                  </div>
                </div>
                {/* New Business */}
                {this.renderLink(
                  <React.Fragment>
                    <div className="p-col-12">
                      {this.renderProductLink(
                        eProduct.MUL,
                        'mul-link',
                        <FormattedHTMLMessage id="product.mul.title" />
                      )}
                    </div>
                    <br />
                    <br />
                    <div className="p-col-12">
                      {this.renderProductLink(
                        eProduct.PAR,
                        'par-link',
                        <FormattedHTMLMessage id="product.par.title" />
                      )}
                    </div>
                    <br />
                    <br />
                    {this.props.locale === 'fr' && <br />}
                    <div className="p-col-12">
                      {this.renderProductLink(eProduct.FT, 'ft-link', <FormattedHTMLMessage id="product.ft.title" />)}
                    </div>
                    <br />
                    <br />
                    {this.props.locale === 'fr' && <br />}
                    <div className="p-col-12">{this.renderProductLink(eProduct.LC, 'lc-link', 'product.cilc')}</div>
                    <br />
                    <br />
                    <div className="p-col-12">
                      {this.renderLinkSy(
                        eProduct.SY,
                        'sy-link',
                        'product.sy',
                        intl.formatMessage({ id: 'product.sy.link' }),
                        intl.formatMessage({ id: 'product.sy.hover' })
                      )}
                    </div>
                  </React.Fragment>,
                  'landing.newbusiness'
                )}
                {/* Inforce */}
                {this.renderLink(
                  <React.Fragment>
                    <div className="p-col-12">{this.renderProductLink(eProduct.IF, 'if-link', 'product.if')}</div>
                    <br />
                    <br />
                    <div className="p-col-12">
                      {this.renderProductLink(eProduct.TermInforce, 'termInforce-link', 'product.terminforce.link')}
                    </div>
                    <br />
                    <br />
                  </React.Fragment>,
                  'landing.inforce.title'
                )}
              </div>
            }
            <div className="p-col-8 landing-right-container" id="product-details" aria-live="polite">
              {(!this.state.product && this.renderHome()) ||
                this.renderProduct(intl, policyNumber, businessType, isLoading)}
            </div>
          </div>
        </React.Fragment>
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = ({ landingPage, app, advisorProfile, coverageTabNavs }) => {
  const coverageState = coverageTabNavs[coverageTabNavs.activeTabId];
  const coverage = coverageState[coverageState.activeTabId];

  return {
    app,
    advisorProfile,
    isError: landingPage.isError,
    isLoading: landingPage.isLoading,
    errors: landingPage.errors,
    errorStatus: landingPage.errorStatus,
    product: landingPage.product,
    policyNumber: landingPage.policyNumber,
    businessType: landingPage.businessType,
    locale: app.locale,
    errorMessage: app.errorMessage,
    isVitalityPlusSelected: coverage.isVitalityPlusSelected ?? false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(Actions, dispatch),
  landingPageActions: bindActionCreators(LandingPageActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(LandingPage)));
