import * as actionTypes from '../../core/actionTypes';
import {
  eCoverageOption,
  eDefault,
  eDefaultCustomVitality,
  eDefaultCustomVitalityMULPAR,
  eField,
  eProduct,
  eStatuses,
  eVitalityStatus,
  eVitalityType,
} from '../../core/configs';
import { getToYear, assignDef, translateVitalityStatus } from '../../core/utils';
import _ from 'lodash';
import { scenarioSeq } from '../../core/sequenceUtils';

const filterStasuses = (statuses, year) => statuses.filter((status) => status.fromYear <= year);

const initState = { activeTabId: scenarioSeq(true), [scenarioSeq(true)]: eDefaultCustomVitality };
const initStateMULPAR = { activeTabId: scenarioSeq(true), [scenarioSeq(true)]: eDefaultCustomVitalityMULPAR };
const customState = (state) => { return { activeTabId: scenarioSeq(true), [scenarioSeq(true)]: state }; };
let fromYear = eDefault.vitalityFromYear;

const reduceOnChange = (state, payload) => {
  const scenarioTabId = payload.scenarioTabId;
  // this means that value was selected from drop down
  if (payload.target === 'vitalityStatus') {
    return {
      ...state,
      [scenarioTabId]: [{ vitalityStatus: payload.value, fromYear }],
    };
  }

  // if coverage option changed, ensure that years past duration are removed
  if (
    payload.target === 'coverage' &&
    (payload.value === eCoverageOption.t10 || payload.value === eCoverageOption.t20)
  ) {
    const coverageDuration = 20;
    return {
      ...state,
      [scenarioTabId]: filterStasuses(state[scenarioTabId], coverageDuration),
    };
  }

  // if client age changed, ensure that years past duration are removed
  if (payload.target === 'client' && payload.field === 'age') {
    const toYear = getToYear(payload.coverageOption, payload.value);
    let scenarios = filterStasuses(state[scenarioTabId], toYear);
    // Preventing the vitality status object from being completely filtered out for Family Term
    // Term-65 & Term Life coverages
    if (
      payload.product == eProduct.FT &&
      (payload.coverageOption === eCoverageOption.t65 || payload.coverageOption === eCoverageOption.tlife)
    ) {
      scenarios = scenarios.length > 0 ? scenarios : state[scenarioTabId];
    }
    return {
      ...state,
      [scenarioTabId]: scenarios,
    };
  }

  return state;
};

const isMulOrPar = (value) => eProduct.MUL === value || eProduct.PAR === value;

const isParInforceVitalityPlus = (policyData) => {
  return policyData.status === eStatuses.inforce && policyData.product === eProduct.PAR && policyData.vitality.type === eVitalityType.plus;
};

const loadParVitalityStatus = (policyData) => {
  const baseCoverage = policyData.coverage.find(c => c.indicatorCode === eField.indicatorCode.base);
  const vitalityCoverage = policyData.vitality.coverage.find(c => c.coverageId === baseCoverage.coverageId);
  const vitalityStatus = policyData.vitality.life.find(l => l.lifeId === vitalityCoverage.lifeId).status;
  return vitalityStatus == eVitalityStatus.na? initStateMULPAR : customState(vitalityStatus.map(status => translateVitalityStatus(status)));
};

const vitalityStatusReducer2 = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_VITALITY_STATUS: {
      if (state[state.activeTabId].length > 1 && action.payload.updateVitality) {
        return initState;
      } else {
        return state;
      }
    }
    case actionTypes.SAVE_VITALITY_STATUS: {
      return {
        ...state,
        [action.payload.scenarioTabId]: [...action.payload.vitalityStatus],
      };
    }
    case actionTypes.SELECT_PRODUCT: {
      if (isMulOrPar(action.payload)) {
        fromYear = eDefault.vitalityFromYearMULPAR;
        return initStateMULPAR;
      } else {
        return state;
      }
    }
    case actionTypes.LOADED_INFORCE_POLICY: {
      if (isMulOrPar(action.payload.policyData.illustration.policy.product)) {
        fromYear = eDefault.vitalityFromYearMULPAR;
        const policyData = action.payload.policyData.illustration.policy;
        if (isParInforceVitalityPlus(policyData)) {
          return loadParVitalityStatus(policyData);
        }
        return initStateMULPAR;
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};

export default function vitalityStatusReducer(state, action) {
  state = state === undefined ? initState : state;
  switch (action.type) {
    case actionTypes.COMMON_INITIALIZE: {
      return initState;
    }
    case actionTypes.OPEN_SAVEDCASE: {
      const { scenarioTabNavs, vitalityStatus } = action.payload.model;
      // This logic to assign all the missing properties from
      // the initial/default to the saved old object with default value.
      const targetState = vitalityStatus;
      assignDef(targetState, initState);
      scenarioTabNavs.tabNavs.forEach((Scenario) => {
        assignDef(targetState[Scenario.id], eDefaultCustomVitality);
      });
      return {
        ...targetState,
      };
    }
    case actionTypes.ADD_SCENARIO_TABNAVS: {
      return {
        ...state,
        activeTabId: action.payload.newTabId,
        [action.payload.newTabId]: [...state[action.payload.currentTabId]],
      };
    }
    case actionTypes.CLICK_SCENARIO_TABNAVS: {
      return {
        ...state,
        activeTabId: action.payload,
      };
    }
    case actionTypes.REMOVE_SCENARIO_TABNAVS: {
      const newState = _.omit(state, [action.payload.id]);

      return {
        ...newState,
        activeTabId: action.payload.newActiveTabId,
      };
    }
    case actionTypes.MWI_ON_CHANGE:
      return reduceOnChange(state, action.payload);
    case actionTypes.SELECT_PRODUCT_IN_CUSTOM: {
      // reset if product is not vitality
      if (eProduct.FTV !== action.payload) {
        return initState;
      } else {
        return state;
      }
    }
    default:
      return vitalityStatusReducer2(state, action);
  }
}
